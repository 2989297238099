import { render, staticRenderFns } from "./Warehouses.vue?vue&type=template&id=05cfc4de&"
import script from "./Warehouses.vue?vue&type=script&lang=js&"
export * from "./Warehouses.vue?vue&type=script&lang=js&"
import style0 from "./Warehouses.vue?vue&type=style&index=0&id=05cfc4de&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05cfc4de')) {
      api.createRecord('05cfc4de', component.options)
    } else {
      api.reload('05cfc4de', component.options)
    }
    module.hot.accept("./Warehouses.vue?vue&type=template&id=05cfc4de&", function () {
      api.rerender('05cfc4de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/rawMaterials/Warehouses.vue"
export default component.exports